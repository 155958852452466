var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landing-template page-wrapper" },
    [
      _c("router-view"),
      _c("OrganismSidebar", [
        _c("div", { staticClass: "menu" }, [
          _c(
            "div",
            [
              _c("div", { staticClass: "subtitle" }, [_vm._v("Portfolio")]),
              _vm._l(_vm.projects, function(project, i) {
                return _c(
                  "a",
                  {
                    key: i,
                    staticClass: "sidebar-link",
                    attrs: {
                      href: _vm.portfolioPageDisabled
                        ? "/" + project.slug
                        : "/project/" + project.slug
                    }
                  },
                  [_vm._v(_vm._s(project.name))]
                )
              })
            ],
            2
          ),
          _c("div", { staticClass: "separator" }),
          _c(
            "div",
            _vm._l(_vm.sections, function(section, i) {
              return section.enabled
                ? _c(
                    "a",
                    {
                      key: i,
                      staticClass: "sidebar-link",
                      attrs: { href: "#" + section.hashAnchor }
                    },
                    [_vm._v(_vm._s(section.menuLabel))]
                  )
                : _vm._e()
            }),
            0
          ),
          _c(
            "div",
            _vm._l(_vm.staticPages, function(page, i) {
              return _c(
                "router-link",
                {
                  key: i,
                  staticClass: "sidebar-link",
                  attrs: {
                    "active-class": "active",
                    tag: "a",
                    to: { name: page.name, params: {} }
                  }
                },
                [_vm._v(_vm._s(page.menuLabel || page.name))]
              )
            }),
            1
          )
        ]),
        _c("div", { staticClass: "social-links" }, [
          _c("a", { attrs: { href: _vm.instagramUrl } }, [_vm._v("Instagram")]),
          _c("a", { attrs: { href: _vm.linkedinUrl } }, [_vm._v("Linkedin")]),
          _c("a", { attrs: { href: _vm.facebookUrl } }, [_vm._v("Facebook")])
        ])
      ]),
      _c("OrganismMenuBar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }