<template>
  <div class="landing-template page-wrapper">
    <router-view></router-view>
    <OrganismSidebar>
      <div class="menu">
        <div>
          <div class="subtitle">Portfolio</div>
          <a v-for="(project, i) in projects" :key="i" class="sidebar-link" :href="portfolioPageDisabled ? `/${project.slug}` : `/project/${project.slug}`">{{ project.name}}</a>
        </div>
        <div class="separator"></div>
        <div>
          <a class="sidebar-link" v-for="(section, i) in sections" v-if="section.enabled" :href="`#${section.hashAnchor}`" :key="i">{{section.menuLabel}}</a>
        </div>
        <div>
          <router-link v-for="(page, i) in staticPages" :key="i" active-class="active" class="sidebar-link" tag="a" :to="{name: page.name, params: {}}">{{ page.menuLabel || page.name }}</router-link>
        </div>
      </div>
      <div class="social-links">
        <a :href="instagramUrl">Instagram</a>
        <a :href="linkedinUrl">Linkedin</a>
        <a :href="facebookUrl">Facebook</a>
      </div>
    </OrganismSidebar>
    <OrganismMenuBar/>
  </div>
</template>

<script>
import OrganismMenuBar from "../components/organisms/OrganismMenuBar";
import OrganismSidebar from "../components/organisms/OrganismSidebar";
import {baseConstants} from "../store/modules";

export default {
  name: "LandingTemplate",
  components: {
    OrganismSidebar,
    OrganismMenuBar
  },
  computed: {
    projects() {
      return this.$store.state.project.projects;
    },
    sections() {
      return this.$route.meta.page.sections;
    },
    generalConfig() {
      return this.$store.state.base.meta.general
    },
    instagramUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.facebookUrl;
    },
    staticPages() {
      return this.generalConfig.staticPages || [];
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false;
    },
  },
  mounted() {
    const self = this;
    document.querySelectorAll('a.sidebar-link[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', self.smoothScroll);
    });
    if (this.$route.hash !== '') {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
  beforeDestroy() {
    const self = this;
    document.querySelectorAll('a.sidebar-link[href^="#"]').forEach(anchor => {
      anchor.removeEventListener('click', self.smoothScroll);
    });
  },
  methods: {
    smoothScroll(e) {
      e.preventDefault();
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR));
      document.querySelector(e.target.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .landing-template {
    display: block;
  }
</style>